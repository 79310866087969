const requireVendorLocalImage = (imageName) => {
    try {
        let image = require(`Vendor_resources/images/${imageName}`).default;
        return image;
    } catch (err) {
        return false;
    }
}

const requireLocalImage = (imageName) => {
    try {
        let image = require(`Images/${imageName}`).default;
        return image;
    } catch (err) {
        return false;
    }
}

export const requireImage = (imageName) => {
    if (process.env.VENDOR_URL) {
        const vendorImage = requireVendorLocalImage(imageName)
        // Fallback to local image in the presence of vendor if 
        // requested image does not exist in vendor folder
        return vendorImage ? vendorImage : requireLocalImage(imageName)
    }
    return requireLocalImage(imageName)
};


const requireVendorLocalVideo = (videoName) => {
    try {
        let video = require(`Vendor_resources/videos/${videoName}`);
        return video;
    } catch (err) {
        return false;
    }
}

const requireLocalVideo = (videoName) => {
    try {
        let video = require(`Videos/${videoName}`);
        return video;
    } catch (err) {
        return false;
    }
}
export const requireVideo = (videoName) => {
    if (process.env.VENDOR_URL) {
        const vendorVideo = requireVendorLocalVideo(videoName)
        // Fallback to local video in the presence of vendor if 
        // requested video does not exist in vendor folder
        return vendorVideo ? vendorVideo : requireLocalVideo(videoName)
    }
    return requireLocalVideo(videoName)
};

/**
 * Load an asset that has been downloaded locally to the vendor_resources folder. 
 * Note that the asset must be download by the
 * built script for it to be available which is not always the case since
 * such asset must be specified in list of items to download in the build script.
 * Prefer utils/getVendorAssetUrl to get the absolute URL to the asset on the host
 * @param {*} assetPath path relative to vendor_resources locally
 * @returns the resource at assetPath relative to vendor folder or false if it does not exist
 */
export const requireVendorAsset = (assetPath) => {
    if (process.env.VENDOR_URL) {
        try {
            let asset = require(`Vendor_resources/${assetPath}`);
            return asset;
        } catch (err) {
            return false;
        }
    }
}
