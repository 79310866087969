import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";

import EmailConfirmation from "./pages/registration/EmailConfirmation";
import Consent from "./pages/consent/index";
import Country from "./pages/country/index";
import NotFound from "./pages/error/NotFound";
import CustomError from "./pages/error/Custom";
import InternalServerError from "./pages/error/InternalServer";
import InvitationError from "./pages/error/Invitation";

import { externalServiceRedirect } from "./utils/api";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
  position: relative;


  @media only screen and (max-width: 940px) {
    overflow-x: hidden;
    padding: 0 24px 0 25px;
  }
`;

let logGARoute = () => false;

if (process.env.NODE_ENV === "production") {
  if (window.GA_PROPERTY_ID) {
    import("react-ga").then(ReactGA => {
      ReactGA.initialize(window.GA_PROPERTY_ID, {
        debugger: true,
        titleCase: false
      });

      logGARoute = ({ location }) => {
        ReactGA.pageview(location.pathname);
        return null;
      };
    });
  }
}

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const onExternalServiceClick = ({ url, type, isOriginUrl }) => () => {
  externalServiceRedirect({ url, intent: type, isOriginUrl });
};

let authRoutes = null;
if (process.env.ENABLE_REGISTRATION) {
  const EmailLinkError = React.lazy(() =>
    import("./pages/login/EmailLinkError")
  );
  const ResetPasswordLinkError = React.lazy(() =>
    import("./pages/password/Error")
  );
  const Register = React.lazy(() => import("./pages/registration/index"));
  const Password = React.lazy(() => import("./pages/password/index"));
  const Login = React.lazy(() => import("./pages/login/index"));
  authRoutes = (
    <Switch>
      <Route path="/email-confirmation-error">
        <EmailLinkError />
      </Route>
      <Route path="/reset-password/link-error">
        <ResetPasswordLinkError />
      </Route>
      <Route path="/register">
        <Register onExternalServiceClick={onExternalServiceClick} />
      </Route>
      <Route path="/reset-password">
        <Password />
      </Route>
      <Route path="/">
        <Login onExternalServiceClick={onExternalServiceClick} />
      </Route>
    </Switch>
  );
}

const SSO = React.lazy(() => import("./pages/sso/index"));

function App() {
  // eslint-disable-next-line no-unused-vars
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 300);

    window.addEventListener("resize", debouncedHandleResize);

    return _ => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    <Layout>
      <Content>
        <Router>
          <Route path="/" render={logGARoute} />
          <Switch>
            <Route path="/not-found">
              <NotFound />
            </Route>
            <Route path="/error">
              <CustomError />
            </Route>
            <Route path="/invitation-error">
              <InvitationError />
            </Route>
            <Route path="/internal-server-error">
              <InternalServerError />
            </Route>
            <Route path="/email-confirmation">
              <EmailConfirmation />
            </Route>
            <Route path="/consent">
              <Consent />
            </Route>
            <Route path="/country">
              <Country />
            </Route>
            {process.env.ENABLE_SSO &&
              <Route path="/sso">
                <SSO />
              </Route>}
            {authRoutes}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Content>
    </Layout>
  );
}

export default App;
