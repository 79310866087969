import { getTracking } from "./cookie";

let authUrl = process.env.AUTH_URL || window.AUTH_URL;

if (process.env.NODE_ENV !== "production") {
  authUrl = process.env.AUTH_URL || "https://auth.plat.farm";
}

const request = async (url, data, method = "POST") => {
  let response;

  try {
    response = await fetch(url, {
      method,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: method !== "GET" ? JSON.stringify(data) : undefined
    });
  } catch (err) {
    throw err;
  }

  const contentType = response.headers.get("Content-Type");

  if (!contentType || !contentType.startsWith("application/json")) {
    return response;
  }

  let resp;
  try {
    resp = await response.json();
  } catch (err) {
    throw new Error(`Error ${response.statusText} (${response.status})`);
  }

  if (Math.round(response.status / 100) > 3) {
    throw resp;
  }

  return resp;
};

export const logIn = async (email_address, password) => {
  const url = `${authUrl}/login/password?${getTracking().toString()}`;

  return await request(url, { email_address, password });
};

export const getLogins = async email_address => {
  const url = `${authUrl}/login/request`;

  return await request(url, { email_address });
};

export const resetPassword = async ({ email, recaptchaResponse, ...body }) => {
  const url = `${authUrl}/auth/forgotpassword`;
  return await request(url, {
    email_address: email,
    "g-recaptcha-response": recaptchaResponse,
    ...body
  });
};

export const submitNewPassword = async body => {
  const url = `${authUrl}/auth/resetpassword`;
  return await request(url, body);
};

export const register = async ({ email_address, confirmPassword, ...body }) => {
  const url = `${authUrl}/auth/register`;
  return await request(url, { email_address, confirmPassword, ...body });
};

export const signUp = async ({ recaptchaResponse, ...body }) => {
  const url = `${authUrl}/signup/request?${getTracking().toString()}`;
  return await request(url, {
    "g-recaptcha-response": recaptchaResponse,
    ...body
  });
};

export const sendPersonalDetail = async body => {
  const url = `${authUrl}/signup/personal-details`;
  return await request(url, body);
};

export const sendCountryInfo = async body => {
  const url = `${authUrl}/signup/country-info`;
  return await request(url, body);
};

// we are temporarily using this endpoint to set the country
// until we have a one-step registration
// and thus a new endpoint api
export const sendCompanyDetail = async body => {
  const url = `${authUrl}/signup/company-details`;
  return await request(url, body);
};

export const createAccount = async body => {
  const url = `${authUrl}/signup/account`;
  return await request(url, body);
};

export const submitTfa = async body => {
  const url = `${authUrl}/login/tfa`;
  return await request(url, body);
};

export const submitSsoTfa = async body => {
  const url = `${authUrl}/auth/tfa`;
  return await request(url, body);
};

export const getSubmitConsentUrl = () => {
  return `${authUrl}/oauth2/consent`;
};

export const externalServiceRedirect = ({ url, intent, isOriginUrl }) => {
  const tracking = getTracking().toString();
  if (isOriginUrl) {
    url = new URL(url).search
      ? `${tracking ? `${url}&${tracking}` : url}`
      : url;
    window.location.href = url;
    return;
  }
  window.location.href = `${authUrl}${url}?intent=${intent}${
    tracking ? `&${tracking}` : ""
  }`;
};

export const isTotpResponse = response =>
  response.code === 401 && response.message === "TFA login is required";

export const apiWrapper = (setLoading, func) => async () => {
  setLoading(true);
  await func();
};

export const isRelative = url => url && url[0] === "/";
