export const RECAPTCHA_SITEKEY =
  window.RECAPTCHA_SITEKEY || process.env.REACT_APP_RECAPTCHA_SITEKEY || "mock";

export const ERRORS = {
  "bad_request_unknown_session": {
    title: "Bad request: Unknown session",
    message: "We could not find your session. Please log in again."
  },
  "not_logged_in": {
    title: "Not logged in",
    message: "Your login session could not be found. Please clear cookies on your web browser and try again."
  },
  "bad_request_unknown_redirect_url": {
    title: "Bad request: Unknown redirect URL",
    message: "Your request cannot be accepted."
  },
  "unsupported_provider_use": {
    title: "Unsupported provider",
    message: "You are attempting to use an unrecognized external identity provider."
  },
  "unsupported_provider_login": {
    title: "Unsupported provider",
    message: "You attempted to log in with an unrecognized external identity provider."
  },
  "session_not_found": {
    title: "Session not found",
    message: "Please clear cookies on your web browser and try again."
  },
  "invalid_state": {
    title: "Invalid state",
    message: "Please clear cookies on your web browser and try again."
  },
  "unauthorized": {
    title: "Unauthorized",
    message: "Could not obtain authorization from the external identity provider."
  },
  "something_is_wrong": {
    title: "Something is wrong",
    message: "Please clear cookies on your web browser and try again."
  },
  "unauthorized_email_address_not_verified": {
    title: "Unauthorized: email address not verified",
    message: "Please verify your email address on the external identity provider."
  },
  "unauthorized_email_address_empty": {
    title: "Unauthorized: email address empty",
    message: "We could not obtain your email address. Please ensure you have a valid email address set on the external identity provider."
  },
  "could_not_assign_username": {
    title: "",
    message: "We could not assign a randomized unique username to you. Please try to sign up again."
  },
  "access_denied_pre_register": {
    title: "Access denied",
    message: "You must be pre-registered before accessing our system."
  },
  "access_denied_not_allowed_auth": {
    title: "Access denied",
    message: "You are not allowed to authenticate with our system."
  },
  "unauthorized_login_session_not_found": {
    title: "Unauthorized",
    message: "We could not find your login session."
  },
  "login_already_used": {
    title: "",
    message: "This login is already used by another user."
  },
  "invalid_signup_link": {
    title: "Invalid signup link",
    message: "The signup verification link you used is not valid. Please sign up again and obtain a new link."
  },
  "invalid_or_expired_link": {
    title: "Invalid or expired signup link",
    message: "The signup verification link you used is not valid. Please sign up again and obtain a new link."
  },
  "github_email_not_verified": {
    title: "GitHub email address not verified",
    message: "GitHub could not provide a verified primary email address for your account. Please visit GitHub to verify your email address."
  },
  "invalid_email": {
    title: "Email address is not valid",
    message: "The email address set on the external identity provider is invalid."
  }
};

export const FEDERATED_AUTH = [
  {
      label: "GitHub",
      name:"github",
      imagePath: "github.svg",
      loginUrl: "/auth/authorize/github",
      signupUrl: "/auth/authorize/github",
  },
  {
      label: "Google",
      name:"google",
      imagePath: "google.svg",
      loginUrl: "/auth/authorize/google",
      signupUrl: "/auth/authorize/google",
  },
  {
      label: "Bitbucket",
      name: "bitbucket",
      imagePath: "bitbucket.svg",
      loginUrl: "/auth/authorize/bitbucket",
      signupUrl: "/auth/authorize/bitbucket",
  },
  {
      label: "GitLab",
      name: "gitLab",
      imagePath: "gitlab.svg",
      loginUrl: "/auth/authorize/gitlab",
      signupUrl: "/auth/authorize/gitlab",
  }
]

export const PRODUCT_ANALYTIC_SCRIPT = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
heap.load("816119933");`
