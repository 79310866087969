import React from "react";
import { injectIntl } from "react-intl";

import Button from "../../components/Button";

import { calcColumnWidth } from "../../utils/layout";

const ErrorPageButton = ({ intl }) => {
  return (
    <Button
      as="a"
      href={window.AUTH_USER_REDIRECT_URL}
      text={intl.formatMessage({ id: "back_to_console" })}
      width={`calc(${calcColumnWidth(2, 6, "end", true)} - 16px)`}
    />
  );
};

export default injectIntl(ErrorPageButton);
