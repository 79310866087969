import React, { useState } from "react";
import { injectIntl } from "react-intl";
import countryList from "react-select-country-list";

import SplitLayout from "../../components/SplitLayout";
import NavBar from "../../components/NavBar";
import BodyFooterLayout from "../../components/BodyFooterLayout";
import LoginImage from "../../images/signin_art_desktop.png";
import Badge from "../../images/leader_winter_2023.png";
import GirlsCodeLogo from "../../images/girls_who_code.png";
import { calcColumnWidth } from "../../utils/layout";
import { sendCountryInfo, apiWrapper } from "../../utils/api";
import Meta from "../../components/Meta";
import Button from "../../components/loading/Button";
import GeneralError from "../../components/GeneralError";
import Title from "../../components/Title";
import Select from "../../components/fields/Select";

import * as S from "../login/styles";
import { Description } from "./styles";

const Country = ({ intl }) => {
  const [country, setCountry] = useState();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const writeAReview = () => {
    window.location =
      "https://www.g2.com/g2gives/girls-who-code/and/platform-sh-may";
  };

  const notAvailableCountries = ["SY", "SS", "CU", "IR", "KP"];
  const options = countryList()
    .getData()
    .filter(c => !notAvailableCountries.find(nac => nac === c.value));

    const urlParams = new URLSearchParams(window.location.search);
    const send = apiWrapper(setLoading, async () => {
      try {
        const resp = await sendCountryInfo({
          country: country && country.value,
          csrf: urlParams.get("csrf")
        });

        window.location.href = resp.redirect_uri;
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    });

  return (
    <SplitLayout>
      <Meta title={intl.formatMessage({ id: "log_in_title" },{ app_name: intl.formatMessage({ id: "app_name" }) })} />
      <S.LeftPane backgroundImage={LoginImage}>
        <S.LeftPaneTitle>
          <img src={Badge} alt="Leader winter 2023 " />
          <S.LeaderFall>
            {intl.formatMessage({ id: "customer_g2" },{ app_name: intl.formatMessage({ id: "app_name" }) })}
          </S.LeaderFall>
        </S.LeftPaneTitle>

        <S.GirlsWhoCode>
          <img src={GirlsCodeLogo} alt="Girls who code" />
          <p>{intl.formatMessage({ id: "girls_who_code_review" })}</p>
          <S.ReviewBtn
            text={intl.formatMessage({ id: "girls_who_code_button" })}
            onClick={writeAReview}
            width={calcColumnWidth(2, 0, "end", true)}
          />
        </S.GirlsWhoCode>
      </S.LeftPane>

      <S.RightPaneLayout >
        <BodyFooterLayout hideSignUpText>
          <NavBar color="black" />
          <Title>{intl.formatMessage({ id: "one_more_step" })}</Title>
          <Description>{intl.formatMessage({ id: "finish_country_setup" })}</Description>
          <Select
            id="country"
            label={intl.formatMessage({ id: "country" })}
            placeholder={intl.formatMessage({ id: "country" })}
            value={country}
            onChange={setCountry}
            options={options}
            error={error && error.detail && error.detail.country}
            required
          />
          <Button
            style={{ marginTop: "40px" }}
            text={intl.formatMessage({ id: "finish_setup" })}
            onClick={send}
            isLoading={isLoading}
          />
          {error && <GeneralError error={error} />}
        </BodyFooterLayout>
      </S.RightPaneLayout>
    </SplitLayout>
  );
};

export default injectIntl(Country);
